import React, {Component} from 'react';
import './ItemsTable.css';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import {genericHelper} from "../../../helpers/genericHelper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import Table from "@mui/material/Table";

class ItemsTable extends Component {

  headers = [
    {id: 'title', label: 'Τίτλος'},
    {id: 'startDate', label: 'Ημερομηνία έναρξης'},
    {id: 'period.title.el', label: 'Περίοδος'},
    {id: 'description', label: 'Περιγραφή'},
    {id: 'category', label: 'Κατηγορία'}
  ];

  state = {
    order: 'asc',
    orderBy: 'startDate',
    page: 0,
    rowsPerPage: 20,
  };


  handleRequestSort = (event, orderBy) => {
    let order = 'asc';
    if (this.state.orderBy === orderBy && this.state.order === 'asc') {
      order = 'desc';
    }
    this.setState({order, orderBy});
  };

  handlePageChange = (event, page) => {
    this.setState({page});
  };

  handleRowsPerPageChange = (event) => {
    this.setState({rowsPerPage: event.target.value});
  };

  render() {
    const items = this.props.items;
    const openItemEditDialog = this.props.openItemEditDialog;
    const openItemDeleteDialog = this.props.openItemDeleteDialog;

    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, items.length - this.state.page * this.state.rowsPerPage);

    return (
      <Table className="ItemsTable">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {this.headers.map(
              header => (
                <TableCell
                  key={header.id}
                  sortDirection={this.state.orderBy === header.id ? this.state.order : false}
                >
                  <TableSortLabel
                    active={this.state.orderBy === header.id}
                    direction={this.state.order}
                    onClick={event => this.handleRequestSort(event, header.id)}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ),
              this,
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {genericHelper.stableSort(items, genericHelper.getSorting(this.state.order, this.state.orderBy))
            .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
            .map(item => {
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={item._id}
                >
                  <TableCell>
                    <div className={'flex'}>
                      <IconButton className="IconButton"
                                  aria-label="Delete"
                                  onClick={event => openItemDeleteDialog(item._id)}
                                  style={{marginTop: '-3px'}} // marginBottom: '10%',
                      >
                        <img alt="delete" style={{maxWidth: '22px'}}
                             src={process.env.PUBLIC_URL + "/images/delete.svg"}/>
                      </IconButton>
                      <IconButton className="IconButton"
                                  aria-label="Edit"
                                  onClick={event => openItemEditDialog(item._id)}
                      >
                        <img alt="delete" style={{maxWidth: '20px'}}
                             src={process.env.PUBLIC_URL + "/images/edit.svg"}/>
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell className="title-cell">{item.title.el}</TableCell>
                  <TableCell className="year-cell">{genericHelper.getGreekDateString(item.startDate)}</TableCell>
                  <TableCell>{item.period?.title?.el}</TableCell>
                  <TableCell>{genericHelper.getTextWithMaxLength(item.description.el, 170)}</TableCell>
                  <TableCell>{genericHelper.getCategoryElNameByEnum(item.category)}</TableCell>
                </TableRow>
              );
            })}
          {emptyRows > 0 && (
            <TableRow style={{height: 49 * emptyRows}}>
              <TableCell colSpan={6}/>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              count={items.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              backIconButtonProps={{'aria-label': 'Previous Page'}}
              nextIconButtonProps={{'aria-label': 'Next Page'}}
              onRowsPerPageChange={this.handleRowsPerPageChange}
              onPageChange={this.handlePageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}

export default ItemsTable;
