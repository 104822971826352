import React, {Component} from 'react';
import './ImageFull.css';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import {TransitionDown} from "../Transition/Transition";


class ImageFull extends Component {
  render() {
    return (
      <Dialog
        classes={{paper: 'full-img-paper'}}
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        TransitionComponent={TransitionDown}
        keepMounted
        aria-labelledby="dialog-img"
        aria-describedby="dialog-img"
      >
        <div className="image-full-container">
          <div className="image-full-close"
               onClick={event => this.props.handleClose()}>
            <CloseIcon style={{fontSize: 30, color: 'white'}}/>
          </div>
          <img className="item-full-img" alt="item-img" src={this.props.image}/>
        </div>
      </Dialog>
    );
  }
}

export default ImageFull;
