import React, {Component} from 'react';
import './ResetPasswordPage.css';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import sharedEnv from "../../shared/sharedEnv";
import {withRouter} from "../../hooks/withRouter";
import {localStorageHelper} from "../../helpers/localStorageHelper";
import {apiService} from "../../services/apiService";

class ResetPasswordPage extends Component {

  state = {
    email: {
      value: '',
      valid: false,
      touched: false
    },
    password: {
      value: '',
      valid: false,
      touched: false
    },
    confirm: {
      value: '',
      valid: false,
      touched: false
    },
    hasExpired: false,
    hasError: false
  };

  async componentDidMount() {
    if (!this.props.params['token']) {
      return;
    }

    try {
      await apiService.resetToken(this.props.params['token']);
    } catch (error) {
      this.setState({
        hasExpired: true
      });
    }

  }

  handleChange = (element) => event => {
    let updatedState = {...this.state};
    updatedState[element].touched = true;
    updatedState[element].value = event.target.value;
    updatedState[element].valid = event.target.value.length > 0;

    if (element === 'password' || element === 'confirm') {
      updatedState.confirm.valid = event.target.value.length > 0
        && updatedState.password.value === updatedState.confirm.value;
    }

    this.setState(updatedState);
  };

  changePassword = async (event) => {
    event.preventDefault();

    let userData = {
      email: this.state.email.value,
      password: this.state.password.value
    };

    try {
      await apiService.changePassword(this.props.params['token'], userData);
      this.setState({
        hasError: false
      }, () => {
        localStorageHelper.clear();
        this.props.navigate('/admin', {replace: true});
      });
    } catch (error) {
      console.log(error);
      this.setState({
        hasError: true
      });
    }
  };

  render() {
    return (
      <div>
        {this.state.hasExpired &&
          <p className="ResetError">
            Κάτι πήγε στραβά.<br/>
            Εάν ακόμα επιθυμείτε να αλλάξετε τον κωδικό σας, παρακαλώ ζητήστε νέο email επαναφοράς.
          </p>
        }
        {!this.state.hasExpired &&
          <div className="ResetPage"
               style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/backgrounds/' + sharedEnv.backgroundImageFileName + ')'}}>
            <Paper className="LogInPaper">
              <p className="LogInTitle">ΑΛΛΑΓΗ ΚΩΔΙΚΟΥ</p>
              <form className="LogInForm" onSubmit={this.changePassword}>
                <FormControl className="LogInFormControl">
                  <TextField
                    className="LogInTextField"
                    type="email"
                    label="Email"
                    value={this.state.email.value}
                    onChange={this.handleChange('email')}
                    margin="normal"
                    required={true}
                  />
                  {this.state.email.touched && !this.state.email.valid &&
                    <FormHelperText className="HelperText">
                      Υποχρεωτικό πεδίο
                    </FormHelperText>}
                </FormControl>

                <FormControl className="LogInFormControl">
                  <TextField
                    className="LogInTextField"
                    label="Password"
                    value={this.state.password.value}
                    onChange={this.handleChange('password')}
                    margin="normal"
                    required={true}
                    type='password'
                  />
                  {this.state.password.touched && !this.state.password.valid &&
                    <FormHelperText className="HelperText">
                      Υποχρεωτικό πεδίο
                    </FormHelperText>}
                </FormControl>

                <FormControl className="LogInFormControl">
                  <TextField
                    className="LogInTextField"
                    label="Confirm Password"
                    value={this.state.confirm.value}
                    onChange={this.handleChange('confirm')}
                    margin="normal"
                    required={true}
                    type='password'
                  />
                  {this.state.confirm.touched && !this.state.confirm.valid &&
                    <FormHelperText className="HelperText">
                      Υποχρεωτικό πεδίο, απαιτεί ίδια τιμή με τον κωδικό
                    </FormHelperText>}
                </FormControl>

                <div className="ButtonWrapper pt-4">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="NoRadiusButton"
                  >
                    Αλλαγη
                  </Button>
                </div>
                {this.state.hasError &&
                  <p className="LogInError">Η αλλαγή δεν μπόρεσε να ολοκληρωθεί.</p>}
              </form>
            </Paper>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(ResetPasswordPage);
