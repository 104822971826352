import React, {Component} from 'react';
import './TimelinePage.css';
import CircularProgress from '@mui/material/CircularProgress';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Snackbar from '@mui/material/Snackbar';
import 'lazysizes';
import 'simplebar-react/dist/simplebar.min.css';
import * as vis from "vis-timeline/standalone";
import {renderToStaticMarkup} from "react-dom/server";
import Carousel from "../../Controls/Carousel/Carousel";
import sharedEnv from "../../shared/sharedEnv";
import {TransitionUp} from "../../Controls/Transition/Transition";
import PeriodComponent from "../../Controls/PeriodComponent/PeriodComponent";
import ItemDialog from "../../Controls/ItemDialog/ItemDialog";
import {withRouter} from "../../hooks/withRouter";
import TimelineItem from "../../Controls/TimelineItems/TimelineItem";
import {withSnackbar} from "../../providers/SnackbarProvider";
import SnackbarTypeEnum from "../../enums/sanckbarTypeEnum";
import {apiService} from "../../services/apiService";
import {genericHelper} from "../../helpers/genericHelper";

class TimelinePage extends Component {

  timeline;
  initStarted = false;
  state = {
    minPeriodYear: -7000,
    periods: [],
    itemData: {},
    itemObjects: [],
    activeItem: {
      _id: -1
    },
    activeFilters: {
      range: {
        start: '',
        startBC: true,
        end: '',
        endBC: true
      },
      categories: {
        political: false,
        building: false,
        exhibits: false
      },
      keywords: ''
    },
    validFilters: true,
    activeItemIndex: -1,
    hasNext: true,
    hasPrev: true,
    activePeriods: [],
    activePeriodFocused: false,
    categoryColors: ['#ff7c44', '#00a99c'],
    dialogOpen: false,
    sidebarOpen: false,
    snackbarOpen: false,
    filtersOpen: false,
    currentLang: 'el',
    loadScreen: true,
    viewOk: true
  };

  cardMargins = {}

  // check screen dimensions & if url has params
  setView = () => {
    let newLang = 'el';
    if (this.props.params['lang'] === 'en') {
      newLang = this.props.params['lang'];
    }

    this.setState({
      viewOk: window.innerWidth > window.innerHeight,
      currentLang: newLang,
      dialogOpen: false
    }, () => {
      const startParam = this.props.params['start'];
      const endParam = this.props.params['end'];
      const itemIDParam = this.props.params['id'];

      const minPeriodYear = this.state.minPeriodYear;
      // const maxYear = this.state.periods[this.state.periods.length - 1].endYear;

      if (startParam && endParam) {
        const startYear = Number(startParam);
        const endYear = Number(endParam);

        if (startYear && startYear >= minPeriodYear && startYear < new Date().getFullYear() &&
          endYear && endYear > minPeriodYear && endYear <= new Date().getFullYear()) {

          this.timeline.setWindow((new Date(startYear, 0, 1)).setFullYear(startYear), (new Date(endYear, 0, 1)).setFullYear(endYear));

          if (itemIDParam && this.state.viewOk) {
            const itemId = itemIDParam;
            setTimeout(() => {
              this.getDialogItem(itemId);
            }, 1000);
          }
        } else {
          this.timeline.setWindow(new Date(minPeriodYear, 0, 1), new Date(new Date().getFullYear(), 11, 31));
        }
      } else {
        this.timeline.setWindow(new Date(minPeriodYear, 0, 1), new Date(new Date().getFullYear(), 11, 31));
      }
    });
  };

  // call setView, init TimelinePage, get periods and items
  componentDidMount() {
    this.initPage();
  }

  async initPage() {
    if (this.initStarted) {
      return;
    }
    this.initStarted = true;

    let container = document.getElementById('timeline');

    try {
      const periods = await apiService.getPeriods()
      const items = await apiService.getItems()
      const minPeriodYear = periods[0].startYear;

      let timelineOptions = {
        stack: false,
        min: new Date(minPeriodYear, 0, 1),
        max: new Date(new Date().getFullYear(), 11, 31),
        // zoomMin: 88128000000 / 2, // 6 months in milliseconds
        zoomMin: 88128000000, // 1 year in milliseconds
        format: {
          minorLabels: (date) => {
            if (this.timeline?.timeAxis?.step?.scale === 'month') {
              let textToShow = genericHelper.getMonthNameFromDate(date, this.state.currentLang);
              if (date.month() === 0) {
                textToShow += '<br>' + genericHelper.getYearFromDate(date, this.state.currentLang);
              }
              return textToShow;
            }

            return genericHelper.getYearFromDate(date, this.state.currentLang);
          },
          majorLabels: () => {
            return ' ';
          }
        }
      };

      let transformed = this.transformItems(items);

      this.setState({
        minPeriodYear: minPeriodYear,
        periods: periods,
        activePeriods: [...Array(periods.length).keys()],
        itemData: transformed.finalData,
        itemObjects: transformed.items
      }, () => {
        this.timeline = new vis.Timeline(container, [], timelineOptions);
        this.setView();

        setTimeout(() => {
          this.setState({
            loadScreen: false,
            currentLang: this.props.params['lang']
          }, () => {
            window.addEventListener('resize', (e) => this.setView());
          });
        }, 1000);

        // custom zoom step
        this.timeline.on('rangechange', (properties) => {
          if (properties.event) {
            let zoomStep = 2.5;
            const currentStep = this.timeline.timeAxis.step.step;
            if (currentStep === 1 || currentStep === 3) {
              zoomStep = 15;
            }

            if (properties.event.wheelDelta) {
              if (properties.event.wheelDelta > 0) {
                this.zoomIn(zoomStep);
              } else {
                this.zoomOut(zoomStep);
              }
            } else {
              if (properties.event.detail < 0) {
                this.zoomIn(zoomStep);
              } else {
                this.zoomOut(zoomStep);
              }
            }
          }
          this.setState({activePeriodFocused: false});
        });

        this.timeline.on('rangechanged', () => {
          this.callRangeChange();
        });

        // open item's dialog or expand cluster
        this.timeline.on('click', (properties) => {
          if (this.state.filtersOpen) {
            this.setState({
              filtersOpen: false
            }, () => {
              this.closeFilters();
            });
          } else if (this.state.sidebarOpen) {
            this.closeSidebar();
          }

          if (properties.what === 'item') {
            const itemClicked = this.timeline.itemsData.get(properties.item);

            if (properties.event.target.className.indexOf('cluster') !== -1) {
              const visibleWindow = this.timeline.getWindow();
              const interval = (visibleWindow.end - visibleWindow.start) / 8;
              this.timeline.setWindow(itemClicked.start.valueOf() - interval, itemClicked.start.valueOf() + interval);
            } else if (properties.event.target.className.indexOf('single') !== -1) {
              this.getDialogItem(itemClicked.id);
            } else if (properties.event.target.className.indexOf('multiple') !== -1) {
              if (properties.event.target.className.indexOf('multiple-slide') !== -1) {
                this.getDialogItem(properties.event.target.id);
              } else if (properties.event.target.className.indexOf('no-image') !== -1) {
                this.getDialogItem(properties.event.path[1].id);
              } else if (properties.event.target.className.indexOf('card') !== -1) {
                this.getDialogItem(properties.event.target.parentNode.parentNode.id);
              }
            }
          }
          this.setState({activePeriodFocused: false});
        });
      });


    } catch (error) {
      console.log(error)
    }
  }

  callRangeChange() {
    this.onRangeChanged();
    // this.newOnRangeChanged();
  }


  // show clusters or items
  onRangeChanged() {

    let totalHeight = 0

    try {
      totalHeight = document.getElementsByClassName('vis-itemset')[0].offsetHeight;
    } catch (e) {
      console.log(e);
    }

    const visibleWindow = this.timeline.getWindow();
    this.focusPeriods(visibleWindow);

    // show single and multiple items
    if (this.timeline.timeAxis.step.scale === 'month') {
      const itemData = this.state.itemData[this.state.currentLang]
      const newItemsDataSet = new vis.DataSet(itemData)
      this.timeline.setItems(newItemsDataSet);

      // init carousels
      const multi = document.querySelectorAll('.multiple-item');

      for (let i = 0; i < multi.length; i++) {
        if (multi[i].children.length > 0) {

          const container = multi[i].querySelector('.multiple-item-container')

          if (container.className.indexOf('init-container-finished') !== -1) {
            continue
          }
          container.className = container.className + ' init-container-finished'


          const carousel = new Carousel({
            selector: container.querySelector('.multiple-siema'),
            loop: true,
            onInit: function () {
              this.addDots();
              this.updateDots();
            },
            onChange: function () {
              this.updateDots()
            }
          });

          multi[i].querySelector('.multiple-prev').addEventListener('click', () => carousel.prev());
          multi[i].querySelector('.multiple-next').addEventListener('click', () => carousel.next());

        }
      }
    }
    // create four clusters
    else {
      // split timeline view field in 4 equal parts ang get their limits

      if (visibleWindow.start.getFullYear() === this.state.minPeriodYear && visibleWindow.end.getFullYear() === new Date().getFullYear()) {
        this.timeline.setItems(new vis.DataSet([]));
        return;
      }

      let interval = visibleWindow.end - visibleWindow.start;
      let quarter = interval / 4;

      let minYear = (new Date(visibleWindow.start.valueOf())).getFullYear()
      let maxYear = (new Date(visibleWindow.end.valueOf())).getFullYear()

      let limits = [visibleWindow.start.valueOf()];
      let middles = [];

      for (let i = 1; i <= 4; i++) {
        limits.push(visibleWindow.start.valueOf() + (i * quarter));
      }
      for (let i = 0; i < 4; i++) {
        middles.push((limits[i] + limits[i + 1]) / 2);
      }

      // let visibleItems = this.state.itemObjects.filter(item => {
      //   return item.startYear >= minYear && item.startYear < maxYear;
      // });
      //
      // visibleItems.sort(function (a, b) {
      //   return a.startYear - b.startYear;
      // });
      //
      // const yearInterval = (maxYear - minYear) / 4


      let newDataArray = [];
      let previousStart = null;
      for (let i = 0; i < 4; i++) {
        // get each cluster's items
        let clusterData = [];
        let clusterYearAvg = 0;
        for (let item of this.state.itemObjects) {
          const itemStartYear = genericHelper.getItemYear(item.startDate);
          if (itemStartYear >= new Date(limits[i]).getFullYear() && itemStartYear < new Date(limits[i + 1]).getFullYear()) {
            clusterData.push(item);
            clusterYearAvg += itemStartYear
          }
        }

        clusterYearAvg = Math.floor(clusterYearAvg / clusterData.length)

        if (clusterData.length > 0) {

          let cardId = clusterData[0]._id;
          let cardClass = 'single';
          if (clusterData.length > 1) {
            cardId = i;
            cardClass = 'cluster';
          }

          let margin = 0;
          let marginId = cardId + '_cluster'
          if (this.cardMargins[marginId] === undefined) {
            margin = Math.floor(Math.random() * (totalHeight / 5));
            this.cardMargins[marginId] = margin;
          } else {
            margin = this.cardMargins[marginId];
          }

          const clusterStyle = 'margin-top: ' + margin + 'px !important;'

          const lineHeight = totalHeight - margin;

          let textClasses = 'card-texts-no-image';
          if (clusterData[0].imageUrls && clusterData[0].imageUrls.length > 0) {
            textClasses = 'card-texts';
          }

          const itemElement = document.createElement('div')
          itemElement.innerHTML = renderToStaticMarkup(
            <TimelineItem
              divLineHeight={lineHeight}
              cardClass={cardClass}
              item={clusterData[0]}
              textClasses={textClasses}
              currentLang={this.state.currentLang}
              categoryColors={this.state.categoryColors}
            />)

          // let start = (new Date(clusterYearAvg, 6, 1, 0, 0, 0, 0)).setFullYear(clusterYearAvg);
          const start = (new Date(new Date(middles[i]).getFullYear(), 6, 1, 0, 0, 0, 0)).setFullYear(new Date(middles[i]).getFullYear());

          newDataArray.push({
            id: cardId,
            start: start,
            className: cardClass + '-item',
            style: clusterStyle,
            selectable: false,
            content: itemElement
          });
        }
      }

      this.timeline.setItems(new vis.DataSet(newDataArray));
    }
  }


  // new show clusters or items
  newOnRangeChanged() {

    let totalHeight = 0

    try {
      totalHeight = document.getElementsByClassName('vis-itemset')[0].offsetHeight;
    } catch (e) {
      console.log(e);
    }

    const visibleWindow = this.timeline.getWindow();
    this.focusPeriods(visibleWindow);

    // show single and multiple items
    if (this.timeline.timeAxis.step.scale === 'month') {
      const itemData = this.state.itemData[this.state.currentLang]
      const newItemsDataSet = new vis.DataSet(itemData)
      this.timeline.setItems(newItemsDataSet);


      // init carousels
      const multi = document.querySelectorAll('.multiple-item');
      for (let i = 0; i < multi.length; i++) {
        if (multi[i].children.length > 0) {



          const container = multi[i].querySelector('.multiple-item-container')
          if (container.className.indexOf('init-container-finished') !== -1) {
            continue
          }
          container.className = container.className + ' init-container-finished'
          const carousel = new Carousel({
            selector: container.querySelector('.multiple-siema'),
            loop: true,
            onInit: function () {
              this.addDots();
              this.updateDots();
            },
            onChange: function () {
              this.updateDots()
            }
          });


          multi[i].querySelector('.multiple-prev').addEventListener('click', () => carousel.prev());
          multi[i].querySelector('.multiple-next').addEventListener('click', () => carousel.next());

        }
      }
    }
    // create four clusters
    else {
      // split timeline view field in 4 equal parts ang get their limits

      if (visibleWindow.start.getFullYear() === this.state.minPeriodYear && visibleWindow.end.getFullYear() === new Date().getFullYear()) {
        this.timeline.setItems(new vis.DataSet([]));
        return;
      }

      let interval = visibleWindow.end - visibleWindow.start;
      let quarter = interval / 3;

      let minYear = (new Date(visibleWindow.start.valueOf())).getFullYear()
      let maxYear = (new Date(visibleWindow.end.valueOf())).getFullYear()

      let limits = [visibleWindow.start.valueOf()];
      let middles = [];

      for (let i = 1; i <= 4; i++) {
        limits.push(visibleWindow.start.valueOf() + (i * quarter));
      }
      for (let i = 0; i < 4; i++) {
        middles.push((limits[i] + limits[i + 1]) / 2);
      }

      let visibleItems = this.state.itemObjects.filter(item => {
        const itemStartYear = genericHelper.getItemYear(item.startDate);
        return itemStartYear >= minYear && itemStartYear < maxYear;
      });

      if (visibleItems.length === 0) {
        this.timeline.setItems(new vis.DataSet([]));
        return;
      }

      visibleItems.sort(function (a, b) {
        return genericHelper.getItemYear(a.startDate) - genericHelper.getItemYear(b.startDate);
      });

      const yearInterval = (maxYear - minYear) / 4

      let firstItemStartYear = genericHelper.getItemYear(visibleItems[0].startDate)
      let newDataArray = [];
      for (let i = 0; i < 4; i++) {
        // get each cluster's items
        let clusterData = [];
        let clusterYearAvg = 0;
        let lastAddedItemIndex = 0
        for (let itemIndex = 0; itemIndex < visibleItems.length; itemIndex++) {
          const item = visibleItems[itemIndex]
          const itemStartYear = genericHelper.getItemYear(item.startDate);

          if (itemStartYear >= firstItemStartYear + (i * yearInterval) && itemStartYear < firstItemStartYear + ((i + 1) * yearInterval)) {
            clusterData.push(item);
            lastAddedItemIndex = itemIndex
            clusterYearAvg += itemStartYear
          }
        }

        clusterYearAvg = Math.floor(clusterYearAvg / clusterData.length)

        if (lastAddedItemIndex + 1 < visibleItems.length) {
          if (clusterYearAvg + yearInterval > genericHelper.getItemYear(visibleItems[lastAddedItemIndex + 1].startDate)) {
            clusterYearAvg = genericHelper.getItemYear(visibleItems[lastAddedItemIndex + 1].startDate) - yearInterval
          }
        }

        if (clusterData.length > 0) {

          let cardId = clusterData[0]._id;
          let cardClass = 'single';
          if (clusterData.length > 1) {
            cardId = i;
            cardClass = 'cluster';
          }

          let margin = 0;
          let marginId = cardId + '_cluster'
          if (this.cardMargins[marginId] === undefined) {
            margin = Math.floor(Math.random() * (totalHeight / 5));
            this.cardMargins[marginId] = margin;
          } else {
            margin = this.cardMargins[marginId];
          }

          const clusterStyle = 'margin-top: ' + margin + 'px !important;'

          const lineHeight = totalHeight - margin;

          let textClasses = 'card-texts-no-image';
          if (clusterData[0].imageUrls && clusterData[0].imageUrls.length > 0) {
            textClasses = 'card-texts';
          }

          const itemElement = document.createElement('div')
          itemElement.innerHTML = renderToStaticMarkup(
            <TimelineItem
              divLineHeight={lineHeight}
              cardClass={cardClass}
              item={clusterData[0]}
              textClasses={textClasses}
              currentLang={this.state.currentLang}
              categoryColors={this.state.categoryColors}
            />)

          let start = (new Date(clusterYearAvg, 6, 1, 0, 0, 0, 0)).setFullYear(clusterYearAvg);

          newDataArray.push({
            id: cardId,
            start: start,
            className: cardClass + '-item',
            style: clusterStyle,
            selectable: false,
            content: itemElement
          });
        }
      }

      this.timeline.setItems(new vis.DataSet(newDataArray));
    }
  }

  // create html items for cards or multiple items
  transformItems(items) {
    items.sort(function (a, b) {
      return genericHelper.getItemYear(a.startDate) - genericHelper.getItemYear(b.startDate);
    });

    let dataByYear = [];
    items.forEach(item => {
      const itemStartYear = genericHelper.getItemYear(item.startDate);

      // match each year with its item(s)
      let found = dataByYear.find(data => {
        if (data && data.startYear === itemStartYear) {
          data.items.push(item);
          return true;
        }
        return false;
      });

      if (!found) {
        dataByYear.push({
          startYear: itemStartYear,
          items: [item]
        })
      }
    });

    let finalData = {
      el: [],
      en: []
    };
    const langs = ['el', 'en'];

    for (let data of dataByYear) {


      const firstItem = data.items[0]

      const cardId = firstItem._id;

      let margin = 0;
      if (this.cardMargins[cardId] === undefined) {
        margin = Math.floor(Math.random() * 100);
        this.cardMargins[cardId] = 'margin-top: ' + margin + 'px !important;'
      } else {
        margin = this.cardMargins[cardId];
      }

      const cardStyle = 'margin-top: ' + margin + 'px !important;'

      // single item
      if (data.items.length === 1) {
        let textClasses = 'card-texts-no-image';
        if (data.items[0].imageUrls && data.items[0].imageUrls.length > 0) {
          textClasses = 'card-texts';
        }

        for (let lang of langs) {

          const itemElement = document.createElement('div')

          itemElement.innerHTML = renderToStaticMarkup(
            <TimelineItem
              cardClass={'single'}
              item={data.items[0]}
              textClasses={textClasses}
              currentLang={this.state.currentLang}
              categoryColors={this.state.categoryColors}
            />)


          const start = genericHelper.getDateFromDateString(data.items[0].startDate)

          finalData[lang].push({
            id: data.items[0]._id,
            start: start,
            className: 'single-item',
            style: cardStyle,
            selectable: false,
            content: itemElement
          });
        }
      } else {
        // multiple items on the same year
        for (let lang of langs) {
          let slides = '';
          for (let item of data.items) {

            let textClasses = 'card-texts-no-image';
            if (item.imageUrls && item.imageUrls.length > 0) {
              textClasses = 'card-texts';
            }

            slides += renderToStaticMarkup(
              <TimelineItem
                cardClass={'multiple'}
                item={item}
                textClasses={textClasses}
                currentLang={this.state.currentLang}
                categoryColors={this.state.categoryColors}
              />)
          }

          const itemElement = document.createElement('div')

          // add class to itemElement

          itemElement.className = 'multiple-item-container vis-item-content'
          itemElement.innerHTML = `
              <div class="line"></div>
              <div class="multiple card-shade"></div>
              <div class="multiple card-container"></div>
              <div class="siema multiple-siema">`
            + slides +
            `</div>
              <div class="arrow-container">
                <button class="multiple-arrow multiple-prev"></button>
                <button class="multiple-arrow multiple-next"></button>
              </div>
            `

          const start = genericHelper.getDateFromDateString(data.items[0].startDate)
          finalData[lang].push({
            id: 'multiple' + data.startYear,
            start: start,
            className: 'multiple-item',
            style: cardStyle,
            selectable: false,
            content: itemElement

          });
        }
      }
    }

    return {finalData, items};
  }

  focusPeriod = () => {
    this.setState({activePeriodFocused: !this.state.activePeriodFocused});
  };

  // animate opening side menu
  openSidebar = () => {
    this.setState({
      sidebarOpen: true
    }, () => {
      document.getElementById('side-menu').style.width = '6%';
      document.getElementById('side-menu').style.height = '54%';
      document.getElementById('side-menu-container').style.display = 'none';
      document.getElementById('side-menu-table').style.display = 'block';
    });
  }

  // animate closing side menu
  closeSidebar = () => {
    if (this.state.filtersOpen) {
      this.setState({
        filtersOpen: false
      }, () => {
        this.closeFilters();
      });
    }

    this.setState({
      sidebarOpen: false
    }, () => {
      document.getElementById('side-menu').style.width = '3%';
      document.getElementById('side-menu').style.height = '15%';
      document.getElementById('side-menu-container').style.display = 'block';
      document.getElementById('side-menu-table').style.display = 'none';
    });
  }

  toggleFilters = () => {
    this.setState({
      filtersOpen: !this.state.filtersOpen
    }, () => {
      if (this.state.filtersOpen) {
        this.openFilters();
      } else {
        this.closeFilters();
      }
    });
  };

  openFilters() {
    document.getElementById('filters').style.display = 'block';
    this.focusFilteredPeriods();
  };

  closeFilters() {
    document.getElementById('filters').style.display = 'none';
  };

  categoryFilterClicked = (value) => {
    let updatedFilters = {...this.state.activeFilters};
    updatedFilters.categories[value] = !this.state.activeFilters.categories[value];

    this.setState({
      activeFilters: updatedFilters
    });
  };

  handleFilterChange = (element) => event => {
    const regex = /^[0-9\b]+$/;
    let updatedFilters = {...this.state.activeFilters};

    if (element === 'keywords') {
      updatedFilters.keywords = event.target.value;
    } else {
      if (event.target.value === '' || regex.test(event.target.value)) {
        updatedFilters.range[element] = event.target.value;
      }
    }

    this.setState({
      activeFilters: updatedFilters
    }, () => {
      if (element !== 'keywords') {
        this.focusFilteredPeriods();
      }
    });
  };

  timeFilterIsBc(value, isTrue) {
    let updatedFilters = {...this.state.activeFilters};
    updatedFilters.range[value] = isTrue;

    if (value === 'startBC' && isTrue === false) {
      updatedFilters.range.endBC = false;
    }
    if (value === 'endBC' && this.state.activeFilters.range.startBC === false) {
      updatedFilters.range.endBC = false;
    }

    this.setState({
      activeFilters: updatedFilters
    }, () => {
      this.focusFilteredPeriods();
    });
  }

  // color line based on filter time input
  focusFilteredPeriods() {
    let filterStart = this.state.activeFilters.range.start;
    let filterEnd = this.state.activeFilters.range.end;

    if (filterStart === '') {
      filterStart = this.state.minPeriodYear;
    } else if (this.state.activeFilters.range.startBC) {
      filterStart = -1 * filterStart;
    } else {
      filterStart = Number(filterStart);
    }

    if (filterEnd === '') {
      filterEnd = new Date().getFullYear();
    } else if (this.state.activeFilters.range.endBC) {
      filterEnd = -1 * filterEnd;
    } else {
      filterEnd = Number(filterEnd);
    }

    let focused = [];
    for (let i = 0; i < this.state.periods.length; i++) {
      focused.push(false);
    }

    if (filterStart <= filterEnd) {
      if (filterStart < -6000) {
        filterStart = -6000;
      }

      for (let i = 0; i < this.state.periods.length; i++) {
        const start = this.state.periods[i].startYear;
        const end = this.state.periods[i].endYear;

        if (i === 0) {
          if ((filterStart >= start && filterStart < end) || (filterEnd > start && filterEnd <= end)) {
            focused[i] = true;
          }
        } else {
          if ((filterStart < end && end <= filterEnd) || (filterStart <= start && start < filterEnd) || (start <= filterStart && filterEnd <= end)) {
            focused[i] = true;
          }
        }
      }
    }

    const periodWidthPercentage = 100 / this.state.periods.length;

    let colors = '';
    let focusedIndexes = [];
    for (let i = 0; i < this.state.periods.length; i++) {
      if (focused[i]) {
        focusedIndexes.push(i);
        colors += this.state.periods[i].color + ' ' + periodWidthPercentage * i + '%, ';
        colors += this.state.periods[i].color + ' ' + periodWidthPercentage * (i + 1) + '%';
      } else {
        colors += '#595959 ' + periodWidthPercentage * i + '%, ';
        colors += '#595959 ' + periodWidthPercentage * (i + 1) + '%';
      }

      if (i < this.state.periods.length - 1) {
        colors += ', ';
      }
    }

    if (focusedIndexes.length > 0) {
      this.setState({validFilters: true});
      document.getElementById('left-handle').style.display = 'block';
      document.getElementById('right-handle').style.display = 'block';
      document.getElementById('left-handle').style.left = periodWidthPercentage * focusedIndexes[0] + '%';
      document.getElementById('right-handle').style.left = periodWidthPercentage * (focusedIndexes[focusedIndexes.length - 1] + 1) + '%';
    } else {
      this.setState({validFilters: false});
      document.getElementById('left-handle').style.display = 'none';
      document.getElementById('right-handle').style.display = 'none';
    }
    document.getElementById('colored-line').style.background = 'linear-gradient(to right, ' + colors + ')';
    document.getElementById('colored-line').style.background = '-o-linear-gradient(left, ' + colors + ')';
    document.getElementById('colored-line').style.background = '-ms-linear-gradient(left, ' + colors + ')';
    document.getElementById('colored-line').style.background = '-moz-linear-gradient(left, ' + colors + ')';
    document.getElementById('colored-line').style.background = '-webkit-linear-gradient(left, ' + colors + ')';
  }

  async applyFilters() {
    let categories = [];
    if (this.state.activeFilters.categories.political) {
      categories.push(0);
    }
    if (this.state.activeFilters.categories.building) {
      categories.push(1);
    }
    if (this.state.activeFilters.categories.exhibits) {
      categories.push(2);
    }

    let stateRange = {...this.state.activeFilters.range};
    let range = {
      start: -6000,
      end: new Date().getFullYear()
    }
    if (stateRange.start !== '') {
      range.start = Number(stateRange.start);
      if (stateRange.startBC) {
        range.start = -1 * range.start;
      }
    }
    if (stateRange.end !== '') {
      range.end = Number(stateRange.end);
      if (stateRange.endBC) {
        range.end = -1 * range.end;
      }
    }

    try {
      const items = await apiService.getFilteredItems(categories, range, this.state.activeFilters.keywords)
      let transformed = this.transformItems(items);
      this.setState({
        itemData: transformed.finalData,
        itemObjects: transformed.items
      }, () => {
        if (range.start === -6000 && range.end === new Date().getFullYear() && this.state.itemObjects.length > 0) {
          range.start = genericHelper.getItemYear(this.state.itemObjects[0].startDate) - 25;
          range.end = genericHelper.getItemYear(this.state.itemObjects[this.state.itemObjects.length - 1].startDate) + 25;
        }
        const start = (new Date(range.start, 0, 1)).setFullYear(range.start);
        const end = (new Date(range.end + 1, 0, 1)).setFullYear(range.end);
        this.timeline.setWindow(start, end);
        this.callRangeChange();
      });

    } catch (error) {
      console.log(error);
    }
  }

  async clearFilters() {

    try {
      const items = await apiService.getItems()
      let transformed = this.transformItems(items);
      this.setState({
        itemData: transformed.finalData,
        itemObjects: transformed.items,
        activeFilters: {
          range: {
            start: '',
            startBC: true,
            end: '',
            endBC: true
          },
          categories: {
            political: false,
            building: false,
            exhibits: false
          },
          keywords: ''
        },
        validFilters: true
      }, () => {

        const start = new Date(this.state.minPeriodYear, 0, 1);
        const end = new Date(new Date().getFullYear(), 11, 31);
        this.timeline.setWindow(start, end);
        this.focusFilteredPeriods();
      });

    } catch (error) {
      console.log(error)
    }
  }

  move(direction) {
    let range = this.timeline.getWindow();
    let interval = range.end - range.start;
    this.timeline.setWindow(range.start.valueOf() - interval * direction, range.end.valueOf() - interval * direction);
  }

  zoomIn(percentage) {
    let range = this.timeline.getWindow();
    let start = range.start.valueOf();
    let end = range.end.valueOf();
    let interval = end - start;
    let newInterval = interval / (1 + percentage);
    let distance = (interval - newInterval) / 2;
    let newStart = start + distance;
    let newEnd = end - distance;

    this.timeline.setWindow(newStart, newEnd);
  }

  zoomOut(percentage) {
    let range = this.timeline.getWindow();
    let start = range.start.valueOf();
    let end = range.end.valueOf();
    let interval = end - start;
    let newStart = start - interval * percentage / 2;
    let newEnd = end + interval * percentage / 2;

    this.timeline.setWindow(newStart, newEnd);
  }

  menuClick = (num) => () => {
    let zoomStep = 2.5;
    const currentStep = this.timeline.timeAxis.step.step;
    if (currentStep === 1 || currentStep === 3) {
      zoomStep = 15;
    }

    if (num === 1) { // zoom in
      this.zoomIn(zoomStep);
    } else if (num === 2) { // zoom out
      this.zoomOut(zoomStep);
    } else if (num === 3) { // left
      this.move(1);
    } else if (num === 4) { // right
      this.move(-1);
    }
  };

  periodClick = (num) => () => {
    if (!(this.state.activePeriods.indexOf(num) > -1 && this.state.activePeriods.length === 1)) {
      this.setState({activePeriodFocused: false});
    }

    for (let i = 0; i < this.state.periods.length; i++) {
      if (num === i) {
        const start = (new Date(this.state.periods[i].startYear, 0, 1)).setFullYear(this.state.periods[i].startYear);
        const end = (new Date(this.state.periods[i].endYear, 0, 1)).setFullYear(this.state.periods[i].endYear);

        this.timeline.setWindow(start, end);
        this.undoFocusPeriod([i]);
        break;
      }
    }
  };

  focusPeriods(visibleWindow) {
    const visibleStart = visibleWindow.start.getFullYear();
    const visibleEnd = visibleWindow.end.getFullYear();
    let focused = [];

    // apply color on focused periods
    if (this.state.periods.length > 0) {
      for (let i = 0; i < this.state.periods.length; i++) {
        const start = this.state.periods[i].startYear;
        const end = this.state.periods[i].endYear;

        if (i === 0) {
          if ((visibleStart >= start && visibleStart < end) || (visibleEnd > start && visibleEnd <= end)) {
            focused.push(i);
          }
        } else {
          if ((visibleStart < end && end <= visibleEnd) || (visibleStart <= start && start < visibleEnd) || (start <= visibleStart && visibleEnd <= end)) {
            focused.push(i);
          }
        }
      }
    }

    for (let i of focused) {
      document.getElementById('period' + i).style.backgroundColor = this.state.periods[i].color || '#000000';
    }
    this.undoFocusPeriod(focused);

    let newHistory = '/' + this.state.currentLang + '/' + visibleStart + '/' + visibleEnd;
    if (this.state.activeItem._id !== -1) {
      newHistory += '/' + this.state.activeItem._id;
    }
    this.props.navigate(newHistory, {replace: true});
  }

  undoFocusPeriod(focused) {
    // focused: array of focused period(s)

    const periodsCount = this.state.periods.length;
    let focusedWidth = 75 / focused.length;
    let nonFocusedWidth = 25 / (periodsCount - focused.length);

    if (focused.length === periodsCount) {
      focusedWidth = 100 / focused.length;
      nonFocusedWidth = 0;
    } else if (focused.length === 0) {
      focusedWidth = 0;
      nonFocusedWidth = 100 / periodsCount;
    }

    for (let i = 0; i < periodsCount; i++) {
      if (focused.indexOf(i) === -1) {
        document.getElementById('period' + i).style.backgroundColor = this.state.periods[i].color || '#000000';
        document.getElementById('period' + i).style.width = nonFocusedWidth + '%';
      } else {
        document.getElementById('period' + i).style.width = focusedWidth + '%';
        document.getElementById('period' + i).style.transform = 'scale(1)';
        document.getElementById('period' + i).style.height = '100%';
        document.getElementById('period' + i).style.marginTop = '0px';
      }
    }
    this.setState({activePeriods: focused});
  }

  getNextItem = () => {
    const visibleWindow = this.timeline.getWindow();
    const visibleStart = visibleWindow.start.getFullYear();
    const visibleEnd = visibleWindow.end.getFullYear();
    const fullWidth = Math.abs(visibleEnd - visibleStart);
    const halfWidth = fullWidth / 2;

    if (this.state.activeItemIndex < this.state.itemObjects.length - 1) {
      const newIndex = this.state.activeItemIndex + 1;
      this.setState({
        activeItem: {
          _id: -1
        }
      }, () => {
        this.setState({
          activeItem: this.state.itemObjects[newIndex],
          activeItemIndex: newIndex,
          hasNext: newIndex !== this.state.itemObjects.length - 1,
          hasPrev: newIndex !== 0
        }, () => {
          const newStartYear = genericHelper.getItemYear(this.state.itemObjects[newIndex].startDate) - halfWidth;
          const newEndYear = genericHelper.getItemYear(this.state.itemObjects[newIndex].startDate) + halfWidth;
          this.timeline.setWindow((new Date(newStartYear, 0, 1)).setFullYear(newStartYear), (new Date(newEndYear, 0, 1)).setFullYear(newEndYear));
        });
      });
    }
  };

  getPrevItem = () => {
    const visibleWindow = this.timeline.getWindow();
    const visibleStart = visibleWindow.start.getFullYear();
    const visibleEnd = visibleWindow.end.getFullYear();
    const fullWidth = Math.abs(visibleEnd - visibleStart);
    const halfWidth = fullWidth / 2;

    if (this.state.activeItemIndex > 0) {
      const newIndex = this.state.activeItemIndex - 1;
      this.setState({
        activeItem: {
          _id: -1
        }
      }, () => {
        this.setState({
          activeItem: this.state.itemObjects[newIndex],
          activeItemIndex: newIndex,
          hasNext: newIndex !== this.state.itemObjects.length - 1,
          hasPrev: newIndex !== 0
        }, () => {
          const newStartYear = genericHelper.getItemYear(this.state.itemObjects[newIndex].startDate) - halfWidth;
          const newEndYear = genericHelper.getItemYear(this.state.itemObjects[newIndex].startDate) + halfWidth;
          this.timeline.setWindow((new Date(newStartYear, 0, 1)).setFullYear(newStartYear), (new Date(newEndYear, 0, 1)).setFullYear(newEndYear));
        });
      });
    }
  };

  async getDialogItem(itemId) {
    if (itemId == null) {
      return;
    }

    try {
      let index;
      const item = await apiService.getItem(itemId);

      for (let item of this.state.itemObjects) {
        if (item._id === itemId) {
          index = this.state.itemObjects.indexOf(item);
          break;
        }
      }

      this.setState({
        activeItem: item,
        activeItemIndex: index,
        dialogOpen: true,
        hasNext: index !== this.state.itemObjects.length - 1,
        hasPrev: index !== 0
      }, () => {
        const visibleWindow = this.timeline.getWindow();
        const visibleStart = visibleWindow.start.getFullYear();
        const visibleEnd = visibleWindow.end.getFullYear();
        this.props.navigate('/' + this.state.currentLang + '/' + visibleStart + '/' + visibleEnd + '/' + itemId, {replace: true});
      });
    } catch (error) {
      console.log(error);
    }
  }

  changeLang = (lang) => {
    this.setState({currentLang: lang}, () => {
      this.props.navigate('/' + lang, {replace: true});
      this.callRangeChange();
    });
  };

  handleClose = () => {
    this.setState({
      dialogOpen: false,
      hasNext: true,
      hasPrev: true,
      activeItem: {
        _id: -1
      }
    }, () => {
      const visibleWindow = this.timeline.getWindow();
      const visibleStart = visibleWindow.start.getFullYear();
      const visibleEnd = visibleWindow.end.getFullYear();
      this.props.navigate('/' + this.state.currentLang + '/' + visibleStart + '/' + visibleEnd, {replace: true});
    });
  };

  openSnackbar = () => {
    this.props.showSnackbar({
      message: this.state.currentLang === 'en' ? 'The url has been copied!' : 'Ο σύνδεσμος αντιγράφηκε!',
      snackbarType: SnackbarTypeEnum.SUCCESS
    });
  };

  render() {
    return (
      <div className="Timeline bg-black">
        {this.state.loadScreen &&
          <div className="cover">
            <div className="cover-icon"><CircularProgress/></div>
          </div>
        }
        {!this.state.viewOk && !this.state.loadScreen &&
          <div className="cover">
            <div className="rotation">
              <ScreenRotationIcon className="rotation-icon animated infinite swing slower" color="primary"/>
            </div>
            {this.state.currentLang === 'en' ? <p className="rotation-text">ROTATE YOUR SCREEN</p> :
              <p className="rotation-text">ΣΤΡΕΨΤΕ ΤΗΝ ΟΘΟΝΗ ΣΑΣ</p>}
          </div>
        }
        {!this.state.loadScreen && this.state.viewOk &&
          <div className="menu">
            {this.state.activePeriods.length <= this.state.periods.length &&
              <div className="menu-button-container menu-button-container-left">
                <div className="button-container button-arrow-container" onClick={this.menuClick(3)}>
                  <img className="button-content" alt="arrow" src={process.env.PUBLIC_URL + "/images/left.svg"}/>
                </div>
                <div className="button-container button-text-container" onClick={this.menuClick(3)}>
                  <div
                    className="button-content button-text">{this.state.currentLang === 'en' ? 'MOVE' : 'ΚΥΛΙΣΗ'}</div>
                </div>
              </div>
            }
            <div className="languages">
              <div className="language-container">
                <div className="language" onClick={() => this.changeLang('en')}
                     style={{opacity: this.state.currentLang === 'en' ? 1 : 0.5}}>ENG
                </div>
              </div>
              <div className="language-container">
                <div className="museum-button">
                  <a href={sharedEnv.hrefLink} target="_blank" rel="noopener noreferrer">
                    <img className="museum-logo" alt={sharedEnv.hrefAlt}
                         src={process.env.PUBLIC_URL + "/logos/" + sharedEnv.logoFileName}/>
                  </a>
                </div>
              </div>
              <div className="language-container">
                <div className="language" onClick={() => this.changeLang('el')}
                     style={{opacity: this.state.currentLang === 'el' ? 1 : 0.5}}>ΕΛ
                </div>
              </div>
            </div>
            {this.state.activePeriods.length < this.state.periods.length &&
              <div className="menu-button-container menu-button-container-right">
                <div className="button-container button-text-container" onClick={this.menuClick(4)}>
                  <div
                    className="button-content button-text">{this.state.currentLang === 'en' ? 'MOVE' : 'ΚΥΛΙΣΗ'}</div>
                </div>
                <div className="button-container button-arrow-container" onClick={this.menuClick(4)}>
                  <img className="button-content" alt="arrow" src={process.env.PUBLIC_URL + "/images/right.svg"}/>
                </div>
              </div>
            }
          </div>
        }
        {!this.state.loadScreen && this.state.viewOk &&
          <div id="side-menu">
            <div id="side-menu-container" onClick={() => this.openSidebar()}>
              <div className="flex items-center side-menu-text-container">
                <div className="side-menu-block">
                  {this.state.currentLang === 'en' ?
                    <img className={'mx-auto'} alt="OPTIONS" src={process.env.PUBLIC_URL + "/images/options_en.png"}/>
                    :
                    <img className={'mx-auto'} alt="ΕΠΙΛΟΓΕΣ" src={process.env.PUBLIC_URL + "/images/options_el.png"}/>
                  }
                </div>
              </div>
              <div className="side-menu-icon-container">
                <div className="side-menu-block">
                  <img className="options-icon" alt="more" src={process.env.PUBLIC_URL + "/images/options.svg"}/>
                </div>
              </div>
            </div>

            <table id="side-menu-table">
              <tbody>
              <tr>
                <td onClick={() => this.toggleFilters()}>
                  <img className={'mx-auto'} alt="filters" src={process.env.PUBLIC_URL + "/images/filters.svg"}/>
                  <br/>
                  {this.state.currentLang === 'en' ? 'FILTERS' : 'ΦΙΛΤΡΑ'}
                </td>
              </tr>
              <tr>
                <td className="flex flex-wrap items-center" onClick={this.menuClick(1)}>
                  <ZoomInIcon className="mx-auto text-center filter-icon"/>
                  <div className={'w-full'}>
                    ZOOM IN
                  </div>
                </td>
              </tr>
              <tr>
                <td className="flex flex-wrap items-center" onClick={this.menuClick(2)}>
                  <ZoomOutIcon className="mx-auto text-center filter-icon"/>
                  <div className={'w-full'}>
                    ZOOM OUT
                  </div>
                </td>
              </tr>
              <tr>
                <td onClick={() => this.closeSidebar()}>
                  <img className={'mx-auto'} alt="close" src={process.env.PUBLIC_URL + "/images/close.svg"}/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        }
        {!this.state.loadScreen && this.state.viewOk &&
          <div id="filters" className={'pt-4'}>
            {this.state.currentLang === 'en' ? <p>BY TIME RANGE &gt;</p> : <p>ΕΥΡΟΣ ΧΡΟΝΟΥ &gt;</p>}
            <div className="filter-input-container">
              <input
                className="time-input"
                type="text" id="from" name="from" maxLength="4"
                value={this.state.activeFilters.range.start}
                onChange={this.handleFilterChange('start')}>
              </input>
              <div style={{display: 'inline-block', float: 'right', paddingRight: '5%'}}>
                <span onClick={() => this.timeFilterIsBc('startBC', true)}
                      style={{opacity: this.state.activeFilters.range.startBC ? 1 : 0.5, marginRight: '20%'}}
                >
                  {this.state.currentLang === 'en' ? 'B.C.' : 'π.Χ.'}
                </span>
                <span onClick={() => this.timeFilterIsBc('startBC', false)}
                      style={{opacity: this.state.activeFilters.range.startBC ? 0.5 : 1}}
                >
                  {this.state.currentLang === 'en' ? 'A.D.' : 'μ.Χ.'}
                </span>
              </div>
            </div>
            <div className="filter-input-container" style={{float: 'right'}}>
              <input
                className="time-input"
                type="text" id="until" name="until" maxLength="4"
                value={this.state.activeFilters.range.end}
                onChange={this.handleFilterChange('end')}>
              </input>
              <div style={{display: 'inline-block', float: 'right', paddingRight: '5%'}}>
                <span onClick={() => this.timeFilterIsBc('endBC', true)}
                      style={{opacity: this.state.activeFilters.range.endBC ? 1 : 0.5, marginRight: '20%'}}
                >
                  {this.state.currentLang === 'en' ? 'B.C.' : 'π.Χ.'}
                </span>
                <span onClick={() => this.timeFilterIsBc('endBC', false)}
                      style={{opacity: this.state.activeFilters.range.endBC ? 0.5 : 1}}
                >
                  {this.state.currentLang === 'en' ? 'A.D.' : 'μ.Χ.'}
                </span>
              </div>
            </div>
            <div className="range-filter">
              <hr id="colored-line"/>
              <div className="handle" id="left-handle"></div>
              <div className="handle" id="right-handle"></div>
            </div>

            {this.state.currentLang === 'en' ? <p>BY CATEGORY &gt;</p> : <p>ΚΑΤΗΓΟΡΙΑ &gt;</p>}
            <div
              className="category-filter"
              style={{opacity: this.state.activeFilters.categories.political ? 1 : 0.5}}
              onClick={() => this.categoryFilterClicked('political')}
            >
              <div className="category-filter-color" style={{background: this.state.categoryColors[0]}}></div>
              {this.state.currentLang === 'en' ? 'Events' : 'Γεγονότα'}
            </div>
            <div
              className="category-filter"
              style={{opacity: this.state.activeFilters.categories.exhibits ? 1 : 0.5}}
              onClick={() => this.categoryFilterClicked('exhibits')}
            >
              <div className="category-filter-color" style={{background: this.state.categoryColors[1]}}></div>
              {this.state.currentLang === 'en' ? 'Exhibits' : 'Εκθέματα'}
            </div>

            {this.state.currentLang === 'en' ? <p>BY KEYWORD &gt;</p> : <p>ΛΕΞΗ - ΚΛΕΙΔΙ &gt;</p>}
            <input
              className="filter-input keyword-input"
              type="text" id="keyword" name="keyword"
              value={this.state.activeFilters.keywords}
              onChange={this.handleFilterChange('keywords')}>
            </input>

            <br/>
            <div className="filter-buttons">
              <div className="filter-button" style={{color: '#a6a6a6'}} onClick={() => this.clearFilters()}>
                {this.state.currentLang === 'en' ? 'CLEAR' : 'ΚΑΘΑΡΙΣΜΟΣ'}
              </div>
              {this.state.validFilters ?
                <div className="filter-button" onClick={() => this.applyFilters()}>
                  {this.state.currentLang === 'en' ? 'APPLY' : 'ΕΦΑΡΜΟΓΗ'}
                </div>
                :
                <div className="filter-button" style={{color: '#a6a6a6', cursor: 'initial !important'}}>
                  {this.state.currentLang === 'en' ? 'APPLY' : 'ΕΦΑΡΜΟΓΗ'}
                </div>
              }
              <div>{this.state.itemObjects.length} {this.state.itemObjects.length === 1 ? (
                this.state.currentLang === 'en' ? 'result' : 'αποτέλεσμα'
              ) : (
                this.state.currentLang === 'en' ? 'results' : 'αποτελέσματα'
              )}</div>
            </div>
          </div>
        }

        <div className="block">
          {!this.state.loadScreen && this.state.viewOk &&
            this.timeline.getWindow().start.getFullYear() === this.state.minPeriodYear &&
            this.timeline.getWindow().end.getFullYear() === new Date().getFullYear() &&
            <div className="flex items-center intro-text pointer-events-none backdrop-blur-sm bg-black/30 h-[97%]">
              <div className={'mx-auto'}>
                {this.state.currentLang === 'en' ? sharedEnv.introTextEN : sharedEnv.introTextGR}
              </div>
            </div>
          }
          <div id="timeline"
               style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/backgrounds/' + sharedEnv.backgroundImageFileName + ')'}}></div>
        </div>
        <div className="periods-container">
          {this.state.periods.map((period, index) => (
            <PeriodComponent
              key={'period' + index}
              period={period}
              index={index}
              activePeriods={this.state.activePeriods}
              activePeriodFocused={this.state.activePeriodFocused}
              currentLang={this.state.currentLang}
              onClick={this.periodClick}
              focusPeriod={this.focusPeriod}
            />
          ))}
        </div>

        <Snackbar
          className="Snackbar"
          open={this.state.snackbarOpen}
          TransitionComponent={TransitionUp}
          ContentProps={{'aria-describedby': 'message-id'}}
          message={<span
            id="message-id"></span>}
        />

        <ItemDialog
          lang={this.state.currentLang}
          item={this.state.activeItem}
          categoryColor={this.state.categoryColors[this.state.activeItem.category]}
          isOpen={this.state.dialogOpen}
          handleClose={this.handleClose}
          getNext={this.getNextItem}
          getPrev={this.getPrevItem}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
          windowStart={this.timeline ? this.timeline.getWindow().start.getFullYear() : ''}
          windowEnd={this.timeline ? this.timeline.getWindow().end.getFullYear() : ''}
          openSnackbar={this.openSnackbar}
        />
      </div>
    );
  }
}

export default withSnackbar(withRouter(TimelinePage));
