import axios from 'axios';
import sharedEnv from "../shared/sharedEnv";
import {authHelper} from "../helpers/authHelper";

const axiosInstance = axios.create({
  baseURL: sharedEnv.baseURL
});



class ApiService {

  config = {
    headers: {
      'Content-Type': 'application/json',
      'x-auth': authHelper.getToken()
    }
  };

  async getItems() {
    const response = await axiosInstance.get('/items');
    return response.data;
  }

  async getFilteredItems(categories, range, keywords) {
    const response = await axiosInstance.post('/filteredItems', {
      categories: categories,
      range: range,
      keywords: keywords
    });
    return response.data;
  }

  async getItem(id) {
    const response = await axiosInstance.get('/items/' + id);
    return response.data;
  }

  async createItem(item) {
    this.config.headers['x-auth'] = authHelper.getToken();
    const response = await axiosInstance.post('/items', item, this.config);
    return response.data;
  }

  async updateItem(item) {
    this.config.headers['x-auth'] = authHelper.getToken();
    const response = await axiosInstance.put('/items/' + item._id, item, this.config);
    return response.data;
  }

  async deleteItem(id) {
    this.config.headers['x-auth'] = authHelper.getToken();
    const response = await axiosInstance.delete('/items/' + id, this.config);
    return response.data;
  }

  async getPeriods() {
    const response = await axiosInstance.get('/periods');
    return response.data;
  }

  async getPeriod(id) {
    const response = await axiosInstance.get('/periods/' + id);
    return response.data;
  }

  async createPeriod(period) {
    this.config.headers['x-auth'] = authHelper.getToken();
    const response = await axiosInstance.post('/periods', period, this.config);
    return response.data;
  }

  async updatePeriod(period) {
    this.config.headers['x-auth'] = authHelper.getToken();
    const response = await axiosInstance.put('/periods/' + period._id, period, this.config);
    return response.data;
  }

  async deletePeriod(id) {
    this.config.headers['x-auth'] = authHelper.getToken();
    const response = await axiosInstance.delete('/periods/' + id, this.config);
    return response.data;
  }

  async login(userData) {
    return await axiosInstance.post('/userLogIn', userData)
  }

  async sendResetMail(email) {
    return await axiosInstance.post('/sendResetMail', {email: email})
  }

  async changePassword(token, userData) {
    return await axiosInstance.post(`/resetPassword/${token}`, userData)
  }

  async resetToken(token) {
    return await axiosInstance.get(`/resetToken/${token}`)
  }
}


export const apiService = new ApiService();
