import {localStorageHelper} from "./localStorageHelper";

class AuthHelper {
  getToken() {
    const loginTime = new Date(localStorageHelper.getLoginTime()).valueOf();

    if (new Date().valueOf() - loginTime >= 10800000) {
      // 3 hours
      localStorageHelper.clear();
    }
    return localStorageHelper.getXAuth();
  }

  isAuthed() {
    return this.getToken() !== null;
  }

  logout() {
    localStorageHelper.clear();
  }
}

export const authHelper = new AuthHelper();
