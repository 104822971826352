import React, {Component} from 'react';
import SimpleBar from 'simplebar-react';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';

import {
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';

import LazyImage from '../LazyImage/LazyImage';
import ImageFull from '../ImageFull/ImageFull';
import './ItemDialog.css';
import sharedEnv from "../../shared/sharedEnv";
import {genericHelper} from "../../helpers/genericHelper";
import {TransitionDown} from "../Transition/Transition";

class ItemDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isImageOpen: false
    };
  }

  displayYear(start, end, lang) {
    return '20/11/2023 μ.Χ. - 24/02/2022 μ.Χ.';

    let symbolA = ' π.Χ.';
    let symbolB = ' μ.Χ.';
    if (lang === 'en') {
      symbolA = ' B.C.';
      symbolB = ' A.D.';
    }

    if (end) {
      if (start < 0 && end >= 0) {
        return (-1 * start) + symbolA + '-' + end + symbolB;
      } else if (start < 0 && end < 0) {
        return (-1 * start) + '-' + (-1 * end) + symbolA;
      }
      return start + '-' + end + symbolB;
    }

    if (start < 0) {
      return (-1 * start) + symbolA;
    }
    return start + symbolB;
  }

  getColor(period) {
    if (period) {
      return 'rgba(' + period.color + ', 1)';
    }
    return '';
  }

  getTitle(period, lang) {
    if (period) {
      return period.title[lang];
    }
    return '';
  }

  handleImageOpen = () => {
    this.setState({
      isImageOpen: true
    });
  };

  handleImageClose = () => {
    this.setState({
      isImageOpen: false
    });
  };

  render() {
    const url = sharedEnv.frontURL + '/' + this.props.lang + '/' +
      this.props.windowStart + '/' + this.props.windowEnd + '/' + this.props.item._id;

    return (
      <Dialog
        classes={{paper: (this.props.item.imageUrls && this.props.item.imageUrls.length > 0 ? 'dialog-paper' : 'dialog-paper-no-img')}}
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        TransitionComponent={TransitionDown}
        keepMounted
        maxWidth={'xl'}
        aria-labelledby="dialog"
        aria-describedby="dialog"
      >
        <div className="dialog-shadow bg-red-500">
          {this.props.hasPrev && <div className="dialog-arrow left" onClick={this.props.getPrev}></div>}
          <div className="dialog-shape">
            <div className="dialog-content">
              <div className="dialog-close" onClick={this.props.handleClose}>
                <img style={{width: '80%'}} alt="close" src={process.env.PUBLIC_URL + "/images/close-dialog.svg"}/>
              </div>

              <div className="left-panel">
                <div className="content">
                  <div
                    className={"left-panel-title-container" + (this.props.item.imageUrls && this.props.item.imageUrls.length > 0 ? " panel-with-image" : " panel-no-image")}>
                    <div className="content">
                      <div
                        className="dialog-img-header">{this.props.item._id !== -1 && this.props.item.imageText != null && this.props.item.imageText[this.props.lang]}</div>
                      <div
                        className="dialog-header">{this.props.item._id !== -1 && this.props.item.title != null && this.props.item.title[this.props.lang]}</div>
                    </div>
                  </div>
                  <div className={this.props.item.imageUrls && this.props.item.imageUrls.length > 0 ? "dialog-desc" : (
                    this.props.item._id !== -1 && this.props.item.title != null && this.props.item.title[this.props.lang].length > 0 ? "dialog-desc-no-image" : "dialog-desc-no-image-no-title"
                  )}>
                    <div
                      className="dialog-place">{this.props.item._id !== -1 && this.props.item.place != null && this.props.item.place[this.props.lang]}</div>
                    {this.props.item._id !== -1 ?
                      <SimpleBar autoHide={false} style={{
                        paddingRight: '18px',
                        height: '95%',
                        whiteSpace: 'pre-line',
                        wordBreak: 'break-word'
                      }}>
                        {this.props.item.description != null && this.props.item.description[this.props.lang]}
                      </SimpleBar>
                      :
                      <div></div>
                    }
                  </div>
                  <div className="category-color">
                    <div style={{height: '100%', width: '50%', background: this.props.categoryColor}}></div>
                    <div style={{
                      height: '100%',
                      width: '50%',
                      background: this.props.categoryColor,
                      opacity: '0.5'
                    }}></div>
                  </div>
                </div>
              </div>

              <div className="right-panel">
                <div className="content">
                  <div className="social-networks-container">
                    <div className="content">
                      <div className="content-share-text">
                        SHARE ON SOCIAL
                      </div>
                      <div
                        className={"social-networks" + (this.props.item.imageUrls && this.props.item.imageUrls.length > 0 ? "" : " social-networks-no-image")}>
                        <div className="social-network">
                          <TwitterShareButton
                            className="social-network-button"
                            url={url}
                            quote={sharedEnv.shareQuote}
                          >
                            <img alt="twitter" src={process.env.PUBLIC_URL + "/images/X_logo.svg"}/>
                          </TwitterShareButton>
                        </div>
                        <div className="social-network">
                          <FacebookShareButton
                            className="social-network-button"
                            url={url}
                            quote={sharedEnv.shareQuote}
                          >
                            <img alt="facebook" src={process.env.PUBLIC_URL + "/images/fb.svg"}/>
                          </FacebookShareButton>
                        </div>
                        <Tooltip className="social-network"
                                 title="Copy url"
                                 placement="right"
                        >
                            <div style={{cursor: 'pointer'}} onClick={
                                () => {
                                  genericHelper.copyToClipboard(url);
                                  this.props.openSnackbar();
                                }
                            }>
                              <img style={{width: '90%'}} alt="copy url"
                                   src={process.env.PUBLIC_URL + "/images/copy.svg"}/>
                            </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className="right-panel-info">
                    <div className="right-panel-period">{this.getTitle(this.props.item.period, this.props.lang)}</div>
                    <div className="right-panel-year"
                         style={{
                           borderBottomColor: this.getColor(this.props.item.period),
                           color: (genericHelper.getItemYear(this.props.item.startDate) < -6000 ? "transparent" : "black")
                         }}>
                      {genericHelper.getGreekItemDateString(this.props.item, this.props.lang)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.hasNext && <div className="dialog-arrow right" onClick={this.props.getNext}></div>}
        </div>

        {this.props.item.imageUrls && this.props.item.imageUrls.length > 0 &&
          <ImageFull
            image={sharedEnv.baseURL + '/' + this.props.item.imageUrls[0].url}
            isOpen={this.state.isImageOpen}
            handleClose={this.handleImageClose}
          />
        }

        {this.props.item.imageUrls && this.props.item.imageUrls.length > 0 &&
          <LazyImage
            image={sharedEnv.baseURL + '/' + this.props.item.imageUrls[0].url}
            onFull={this.handleImageOpen}
          />
        }
      </Dialog>
    );
  }
}

export default ItemDialog;
