import React, {Component} from 'react';
import './LoginPage.css';
import sharedEnv from "../../shared/sharedEnv";
import {authHelper} from "../../helpers/authHelper";
import {Navigate} from "react-router-dom";
import LogIn from "../../Controls/Admin/LogIn/LogIn";
import {withSnackbar} from "../../providers/SnackbarProvider";
import SnackbarTypeEnum from "../../enums/sanckbarTypeEnum";

class LoginPage extends Component {

  resetClicked = (isTrue) => {
    this.props.showSnackbar({
      message: isTrue ? 'Μόλις λάβατε email!' : 'Κάτι πήγε λάθος, προσπαθήστε ξανά!',
      snackbarType: isTrue ? SnackbarTypeEnum.SUCCESS : SnackbarTypeEnum.ERROR
    });
  };

  render() {
    if (authHelper.isAuthed()) {
      return <Navigate replace to="/admin"/>;
    }

    return (
      <div className={'LoginPageContainer'}
           style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/backgrounds/' + sharedEnv.backgroundImageFileName + ')'}}>
        <LogIn className={'rounded-3xl'}
               resetClicked={this.resetClicked.bind(this)}
        />
      </div>
    )
  }
}

export default withSnackbar(LoginPage);
