import {Component, Fragment} from "react";
import sharedEnv from "../../shared/sharedEnv";


class TimelineItemContent extends Component {

  moreTexts = {
    el: 'ΠΕΡΙΣΣΟΤΕΡΑ',
    en: 'SHOW MORE'
  };

  render() {
    let cardClass = this.props.cardClass;
    let item = this.props.item;
    let textClasses = this.props.textClasses;
    let categoryColors = this.props.categoryColors;
    let currentLang = this.props.currentLang;

    return (
      <Fragment>
        {item.imageUrls && item.imageUrls.length > 0 && (
          <div className={`${cardClass} card-img-container `}>
            <img className={`lazyload ${cardClass} card-img `}
                 alt="card"
                 src={process.env.PUBLIC_URL + '/images/spinner.gif'}
                 data-src={sharedEnv.baseURL + '/' + item.imageUrls[0].url}/>
          </div>
        )}

        <div className={`${cardClass} ${textClasses} `}>
          <p className={`${cardClass} card-title `}>
            {item.title[currentLang]}
          </p>
          <div className={`${cardClass} card-category `}>
            <div
              style={{height: '100%', width: '50%', background: categoryColors[item.category]}}>

            </div>
            <div
              style={{height: '100%', width: '50%', background: categoryColors[item.category]}}>

            </div>
          </div>
          <p className={`${cardClass} card-desc `}>
            {item.description[currentLang].split('\n')[0]}
          </p>
        </div>
        {cardClass !== 'cluster' && (
          <div className={`${cardClass} card-more-container `}>
            <div>{this.moreTexts[currentLang]}</div>
          </div>)}
      </Fragment>
    )
  }
}

export default TimelineItemContent;
