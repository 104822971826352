class Period {
  _id = '';
  title = { el: '', en: ''}
  description = { el: '', en: ''}
  startYear = '';
  endYear = ''
  color = '';
}

module.exports = Period;
