import {Component} from "react";
import TimelineItemContent from "./TimelineItemContent";


class TimelineItem extends Component {

  render() {
    let divLineHeight = this.props.divLineHeight;
    let cardClass = this.props.cardClass;
    let item = this.props.item;
    let textClasses = this.props.textClasses;
    let categoryColors = this.props.categoryColors;
    let currentLang = this.props.currentLang;
    const containerClassNames = cardClass === 'multiple' ? 'multiple-slide' : '';

    return (
      <div className={`${containerClassNames}`} id={item._id}>
        {cardClass === 'single' && (
          <div className="line"></div>
        )}

        {cardClass === 'cluster' && (
          <div style={{height: divLineHeight + 'px'}} className="line"></div>
        )}

        {cardClass !== 'multiple' && (
          <div className="card-container"></div>
        )}

        {cardClass === 'cluster' && (
          <div className="cluster-more more1"></div> &&
          <div className="cluster-more more2"></div>
        )}

        {cardClass !== 'multiple' && (
          <div className={`${cardClass} card-content `}>
            <TimelineItemContent
              cardClass={cardClass}
              item={item}
              textClasses={textClasses}
              categoryColors={categoryColors}
              currentLang={currentLang}
            />
          </div>
        )}

        {cardClass === 'multiple' && (
          <TimelineItemContent
            cardClass={cardClass}
            item={item}
            textClasses={textClasses}
            categoryColors={categoryColors}
            currentLang={currentLang}
          />
        )}

      </div>
    )
  }
}

export default TimelineItem;
