import React, {Component} from 'react';
import './LogIn.css';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import sharedEnv from "../../../shared/sharedEnv";
import {localStorageHelper} from "../../../helpers/localStorageHelper";
import {withRouter} from "../../../hooks/withRouter";
import {apiService} from "../../../services/apiService";
import SnackbarTypeEnum from "../../../enums/sanckbarTypeEnum";
import {withSnackbar} from "../../../providers/SnackbarProvider";
import DateTextField from "../../DateTextField/DateTextField";


class LogIn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: '',
        valid: false,
        touched: false
      },
      password: {
        value: '',
        valid: false,
        touched: false
      },
      isClicked: false
    };
  }

  handleChange = (element) => event => {
    let updatedState = {...this.state};
    updatedState[element].touched = true;
    updatedState[element].value = event.target.value;
    updatedState[element].valid = event.target.value.length > 0;

    this.setState(updatedState);
  };

  logInUser = async (event) => {
    event.preventDefault();

    let userData = {
      email: this.state.email.value.trim(),
      password: this.state.password.value
    };

    try {
      const response = await apiService.login(userData);
      localStorageHelper.clear();
      localStorageHelper.setXAuth(response.headers['x-auth']);
      localStorageHelper.setLoginTime(new Date());
      this.props.showSnackbar({
        message: 'Επιτυχής σύνδεση!',
        snackbarType: SnackbarTypeEnum.SUCCESS
      });
      this.props.navigate('/admin');
    } catch (error) {
      this.props.showSnackbar({
        message: 'Συμπληρώστε σωστά τα στοιχεία σας.',
        snackbarType: SnackbarTypeEnum.ERROR
      });
    }
  };

  resetPassword = async () => {
    if (this.state.email.valid && !this.state.isClicked) {
      try {
        await apiService.sendResetMail(this.state.email.value);
        this.setState({
          isClicked: true
        });
        this.props.resetClicked(true);
      } catch (error) {
        console.log(error);
        this.props.resetClicked(false);
      }
    }
  };


  render() {
    return (
      <Paper className="LogInPaper">
        <p className="LogInTitle pb-2">ΣΥΝΔΕΣΗ ΧΡΗΣΤΗ</p>
        <form className="LogInForm" onSubmit={this.logInUser}>
          <FormControl className="LogInFormControl">
            <TextField
              data-test={"email"}
              className="LogInTextField"
              type="email"
              label="Email"
              value={this.state.email.value}
              onChange={this.handleChange('email')}
              margin="normal"
              required={true}
            />
            {this.state.email.touched && !this.state.email.valid &&
              <FormHelperText className="HelperText">
                Υποχρεωτικό πεδίο
              </FormHelperText>}
          </FormControl>

          <FormControl className="LogInFormControl">
            <TextField
              data-test={"password"}
              className="LogInTextField"
              label="Password"
              value={this.state.password.value}
              onChange={this.handleChange('password')}
              margin="normal"
              required={true}
              type='password'
            />
            {this.state.password.touched && !this.state.password.valid &&
              <FormHelperText className="HelperText">
                Υποχρεωτικό πεδίο
              </FormHelperText>}
          </FormControl>

          <div className="ButtonWrapper">
            <Button
              data-test={"login-button"}
              type="submit"
              variant="contained"
              color="primary"
              className="NoRadiusButton"
            >
              Συνδεση
            </Button>
          </div>
          <div className="ForgotPassword pt-4">
            <p onClick={this.resetPassword}
               style={{cursor: 'pointer'}}
            >
              Επαναφορά κωδικού
            </p>
          </div>
        </form>
        <div
          style={{
            position: 'fixed', bottom: '10px',
            right: '10px', backgroundColor: 'white',
            paddingLeft: '10px', paddingRight: '10px',
            paddingTop: '5px', paddingBottom: '5px',
            borderRadius: '20px',
          }}>AppVersion: {sharedEnv.appVersion}</div>
      </Paper>
    )
  }
}

export default withSnackbar(withRouter(LogIn));
