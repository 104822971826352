import LocalStorageKeysEnum from "../enums/localStorageKeysEnum";


class LocalStorageHelper {

    getXAuth() {
        return localStorage.getItem(LocalStorageKeysEnum.xAuth);
    }

    setXAuth(xAuth) {
        localStorage.setItem(LocalStorageKeysEnum.xAuth, xAuth);
    }

    getLoginTime() {
        return localStorage.getItem(LocalStorageKeysEnum.loginTime);
    }


    setLoginTime(loginTime) {
        localStorage.setItem(LocalStorageKeysEnum.loginTime, loginTime);
    }

    clear() {
        localStorage.clear();
    }
}


export const localStorageHelper = new LocalStorageHelper();
