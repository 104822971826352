import Slide from "@mui/material/Slide";
import React from "react";

export const TransitionUp = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} children={props.children}/>
))

export const TransitionDown = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} children={props.children}/>
))
