import {
  Snackbar, SnackbarContent,
} from "@mui/material"
import React, {createContext, useContext, useState} from "react"
import {TransitionUp} from "../Controls/Transition/Transition";
import {genericHelper} from "../helpers/genericHelper";

// Create the context so we can use it in our App
const SnackbarContext = createContext(() => {
  throw new Error("Component is not wrapped with a SnackbarProvider.")
})

const SnackbarProvider = ({children}) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState({
    message: "",
    snackbarType: null,
  })
  const showSnackbar = options => {
    // When the dialog is shown, keep the promise info, so we can resolve later
    return new Promise((resolve, reject) => {
      setOptions(options)
      setOpen(true)
      setTimeout(() => {
        setOpen(false)
      }, 3000);
    })
  }
  return (
    <>
      <Snackbar
        className="Snackbar"
        open={open}
        TransitionComponent={TransitionUp}
      >
        <SnackbarContent style={{
          backgroundColor: genericHelper.getSnackbarBackgroundColor(options.snackbarType),
        }}
                         message={<span id="client-snackbar">{options.message}</span>}
        />
      </Snackbar>
      <SnackbarContext.Provider value={showSnackbar}>
        {children}
      </SnackbarContext.Provider>
    </>
  )
}

export const withSnackbar = Component => props => {
  const showSnackbar = useSnackbar()
  return <Component {...props} showSnackbar={showSnackbar}/>
}

// By calling `useSnackbar()` in a component we will be able to use the `showSnackbar()` function
export const useSnackbar = () => {
  return useContext(SnackbarContext)
}

export default SnackbarProvider
