import React, {Component} from 'react';
import './AdminPage.css';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {authHelper} from "../../helpers/authHelper";
import {apiService} from "../../services/apiService";
import sharedEnv from "../../shared/sharedEnv";
import {withRouter} from "../../hooks/withRouter";
import ItemsTable from "../../Controls/Admin/ItemsTable/ItemsTable";
import PeriodsTable from "../../Controls/Admin/PeriodsTable/PeriodsTable";
import ItemAddEditForm from "../../Controls/Admin/ItemAddEditForm/ItemAddEditForm";
import PeriodAddEditForm from "../../Controls/Admin/PeriodAddEditForm/PeriodAddEditForm";
import {withDialog} from "../../providers/DialogProvider";
import {localStorageHelper} from "../../helpers/localStorageHelper";
import SnackbarTypeEnum from "../../enums/sanckbarTypeEnum";
import {withSnackbar} from "../../providers/SnackbarProvider";


class AdminPage extends Component {

  state = {
    currentTab: 0,
    periods: [],
    items: [],
    availableCategories: [
      'Γεγονός',
      'Έκθεμα'
    ],
    headers: [
      {id: 'title', label: 'Τίτλος'},
      {id: 'startYear', label: 'Έτος έναρξης'},
      {id: 'description', label: 'Περιγραφή'},
      {id: 'category', label: 'Κατηγορία'}
    ],
    order: 'asc',
    orderBy: 'startYear',
    page: 0,
    rowsPerPage: 20,
    selectedPeriod: '',
    selectedItem: '',
    editItem: false,
    editPeriod: false,
    isPeriodDialogOpen: false,
    isItemDialogOpen: false
  };

  componentDidMount() {
    if (this.checkIsAuthed()) {
      this.initPage();
    }
  }

  checkIsAuthed = () => {
    if (!authHelper.isAuthed()) {
      setTimeout(() => {
        this.props.navigate('/login');
      }, 100);
      return false;
    }
    return true;
  }

  async getItems() {
    try {
      const items = await apiService.getItems()
      this.setState({items: items});
    } catch (error) {
      console.log(error)
    }
  }

  async getPeriods() {
    try {
      const periods = await apiService.getPeriods()
      this.setState({periods: periods});
    } catch (error) {
      console.log(error)
    }
  }

  async initPage() {
    await this.getAllData();
  }

  async getAllData() {
    await this.getItems();
    await this.getPeriods();
  }

  handleTabChange = (event, value) => {
    this.setState({currentTab: value});
  };

  openLogoutDialog = async () => {
    const confirmed = await this.props.showDialog({
      title: 'Θέλετε να αποσυνδεθείτε;',
      confirmText: 'Αποσύνδεση',
      cancelText: 'Όχι',
    });
    if (confirmed) {
      authHelper.logout();
      this.props.navigate('/login');
    }
  }

  openItemAddDialog = () => {
    this.setState({isItemDialogOpen: true});
  };

  openItemEditDialog = (id) => {
    this.setState({
      selectedItem: id,
      isItemDialogOpen: true,
      editItem: true
    });
  };

  closeItemDialog = async () => {
    this.setState({
      selectedItem: '',
      isItemDialogOpen: false,
      editItem: false
    });
    await this.getAllData();
  };

  openPeriodAddDialog = () => {
    this.setState({
      isPeriodDialogOpen: true
    });
  };

  openPeriodEditDialog = (id) => {
    this.setState({
      selectedPeriod: id,
      editPeriod: true,
      isPeriodDialogOpen: true
    });
  };

  closePeriodDialog = async () => {
    this.setState({
      selectedPeriod: '',
      editPeriod: false,
      isPeriodDialogOpen: false
    });
    await this.getAllData();
  };

  openItemDeleteDialog = async (itemId) => {
    const confirmed = await this.props.showDialog({
      title: 'Θέλετε να διαγράψετε το αντικείμενο;',
      confirmText: 'Διαγραφή',
      cancelText: 'Όχι',
    });

    if (confirmed) {
      try {
        await apiService.deleteItem(itemId);
        this.props.showSnackbar({
          message: 'Το αντικείμενο διαγράφηκε επιτυχώς',
          snackbarType: SnackbarTypeEnum.SUCCESS
        });
        await this.getAllData();
      } catch (error) {
        console.log(error);
        this.props.showSnackbar({
          message: 'Το αντικείμενο δεν διαγράφηκε',
          snackbarType: SnackbarTypeEnum.ERROR
        });
        if (error.response.status === 401) {
          localStorageHelper.clear();
          this.props.navigate('/login');
        }
      }
    }
  };


  openPeriodDeleteDialog = async (periodId) => {
    const confirmed = await this.props.showDialog({
      title: 'Θέλετε να διαγράψετε την περίοδο;',
      confirmText: 'Διαγραφή',
      cancelText: 'Όχι',
    });

    if (confirmed) {
      try {
        await apiService.deletePeriod(periodId);
        this.props.showSnackbar({
          message: 'Η περίοδος διαγράφηκε επιτυχώς',
          snackbarType: SnackbarTypeEnum.SUCCESS
        });
        await this.getAllData();
      } catch (error) {
        console.log(error);
        this.props.showSnackbar({
          message: 'Η περίοδος δεν διαγράφηκε',
          snackbarType: SnackbarTypeEnum.ERROR
        });
        if (error.response.status === 401) {
          localStorageHelper.clear();
          this.props.navigate('/login');
        }
      }
    }
  }

  render() {
    return (
      <div className="AdminPage relative"
           style={{backgroundImage: 'url(' + process.env.PUBLIC_URL + '/backgrounds/' + sharedEnv.backgroundImageFileName + ')'}}>

        <div className="Logout absolute top-3 right-3">
          <Button
            variant="contained"
            onClick={this.openLogoutDialog}
            className="text-white bg-red-500 hover:bg-red-700"
            sx={{
              backgroundColor: 'white',
              ":hover": {
                color: 'white',
              },
              height: 30,
              boxShadow: 0,
              borderRadius: 0,
            }}
          >
            Αποσύνδεση
          </Button>
        </div>

        <div className="AdminContainer">
          <div className="flex items-center">
            <Tabs
              value={this.state.currentTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className="AdminTabs w-fit px-8"
            >
              <Tab className="AdminTab"
                   label={"Αντικείμενα" + (this.state.items.length > 0 ? ' (' + this.state.items.length + ')' : '')}/>
              <Tab className="AdminTab"
                   label={"Περίοδοι" + (this.state.periods.length > 0 ? ' (' + this.state.periods.length + ')' : '')}/>
            </Tabs>
            <div className="grow">
            </div>
          </div>

          <Paper className="AdminPaper">
            {this.state.currentTab === 0 &&
              <ItemsTable
                items={this.state.items}
                openItemEditDialog={this.openItemEditDialog}
                openItemDeleteDialog={this.openItemDeleteDialog}
              />
            }

            {this.state.currentTab === 1 &&
              <PeriodsTable
                periods={this.state.periods}
                openPeriodEditDialog={this.openPeriodEditDialog}
                openPeriodDeleteDialog={this.openPeriodDeleteDialog}
              />
            }
          </Paper>

          {this.state.currentTab === 0 &&
            <div className="AddButton">
              <Button
                variant="contained"
                color="primary"
                onClick={this.openItemAddDialog}
                className="NoRadiusButton"
              >
                Προσθηκη νεου αντικειμενου
              </Button>
            </div>
          }

          {this.state.currentTab === 1 &&
            <div className="AddButton">
              <Button
                variant="contained"
                color="primary"
                onClick={this.openPeriodAddDialog}
                className="NoRadiusButton"
              >
                Προσθηκη νεας περιοδου
              </Button>
            </div>
          }

        </div>

        {authHelper.isAuthed() && <ItemAddEditForm
          isOpen={this.state.isItemDialogOpen}
          isEdit={this.state.editItem}
          item={this.state.selectedItem}
          periods={this.state.periods}
          handleClose={this.closeItemDialog.bind(this)}
          getToken={authHelper.getToken.bind(this)}
        />}
        {authHelper.isAuthed() && <PeriodAddEditForm
          isOpen={this.state.isPeriodDialogOpen}
          isEdit={this.state.editPeriod}
          period={this.state.selectedPeriod}
          handleClose={this.closePeriodDialog.bind(this)}
          getToken={authHelper.getToken.bind(this)}
        />}
      </div>
    )
  }
}

export default withSnackbar(withDialog(withRouter(AdminPage)));
