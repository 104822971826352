class Item {
  _id = '';
  title = {
    el: '',
    en: ''
  };
  description = {
    el: '',
    en: ''
  };
  place = {
    el: '',
    en: ''
  };
  startDate = null
  endDate = null
  startDateFormat = null
  endDateFormat = null
  startYear = null;
  endYear = null;
  period = null;
  category = null;
  imageUrls = [];
  imageText = {
    el: '',
    en: ''
  }
  imagesBase64Array = [];
  imagesToRemove = [];
}

module.exports = Item;
