class DateFormatEnum {
  static date = new DateFormatEnum('date');
  static monthYear = new DateFormatEnum('monthYear');
  static year = new DateFormatEnum('year');


  constructor(value) {
    this.value = value;
  }

  toString() {
    return `${this.value}`;
  }
}

export default DateFormatEnum;
