import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material"
import {createContext, useContext, useState} from "react"

// Create the context so we can use it in our App
const DialogContext = createContext(() => {
  throw new Error("Component is not wrapped with a DialogProvider.")
})

const DialogProvider = ({children}) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState({
    title: "",
    confirmText: "OK",
    cancelText: "Cancel",
  })
  const [promiseInfo, setPromiseInfo] = useState()
  const showDialog = options => {
    // When the dialog is shown, keep the promise info so we can resolve later
    return new Promise((resolve, reject) => {
      setPromiseInfo({resolve, reject})
      setOptions(options)
      setOpen(true)
    })
  }
  const handleConfirm = () => {
    // if the Confirm button gets clicked, resolve with `true`
    setOpen(false)
    promiseInfo?.resolve(true)
    setPromiseInfo(undefined)
  }
  const handleCancel = () => {
    // if the dialog gets canceled, resolve with `false`
    setOpen(false)
    promiseInfo?.resolve(false)
    setPromiseInfo(undefined)
  }
  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{options.title}</DialogTitle>
        <DialogContent sx={{minWidth: "400px"}}>
          {options.message && (
            <DialogContentText>{options.message}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>
            {options.cancelText || "Cancel"}
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            {options.confirmText || "OK"}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogContext.Provider value={showDialog}>
        {children}
      </DialogContext.Provider>
    </>
  )
}

export const withDialog = Component => props => {
  const showDialog = useDialog()
  return <Component {...props} showDialog={showDialog}/>
}

// By calling `useDialog()` in a component we will be able to use the `showDialog()` function
export const useDialog = () => {
  return useContext(DialogContext)
}

export default DialogProvider
