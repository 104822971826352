import React, {Component} from 'react';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import moment from "moment/moment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DateFormatEnum from "../../enums/dateFormatEnum";

moment.updateLocale('el', {
  eras: [
    {
      since: '0001-01-01',
      until: +Infinity,
      offset: 1,
      name: 'μετά Χριστόν',
      narrow: 'μ.Χ.',
      abbr: 'μ.Χ.'
    },
    {
      until: -Infinity,
      since: '0000-12-31',
      offset: 1,
      name: 'προ Χριστού',
      narrow: 'π.Χ.',
      abbr: 'π.Χ.'
    }
  ],
});

class DateTextField extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check if this is the initial render

    if (prevProps.dateFormatEnum !== this.props.dateFormatEnum || this.state.dateFormatEnum !== this.props.dateFormatEnum) {
      const displayValue = this.getDisplayValue(this.state.value, this.props.dateFormatEnum);
      this.setState({
        dateFormatEnum: this.props.dateFormatEnum,
        displayValue: displayValue,
      });
    }

    if (prevProps.initialValue && !this.props.initialValue) {
      this.setState({
        label: this.props.label,
        value: null,
        displayValue: '',
        era: 'μ.Χ.',
        dateFormatEnum: this.props.dateFormatEnum
      });
    }

    if (!prevProps.initialValue && this.props.initialValue) {
      moment.locale('el');

      const displayValue = this.getDisplayValue(this.props.initialValue, this.props.dateFormatEnum);

      this.setState({
        label: this.props.label,
        value: this.props.initialValue,
        displayValue: this.props.initialValue != null ? displayValue : '',
        era: this.props.initialValue != null && this.props.initialValue.getFullYear() < 0 ? 'π.Χ.' : 'μ.Χ.',
        dateFormatEnum: this.props.dateFormatEnum
      });
    }
  }


  state = {
    label: 'Ημερομηνία',
    value: null,
    displayValue: '',
    valid: false,
    touched: false,
    era: 'μ.Χ.',
    dateFormatEnum: DateFormatEnum.date.toString()
  }

  getDateFromString = (dateString, dateFormatEnum) => {

    if (dateString == null || dateString === '') {
      return null;
    }

    let dateStringToConvert = ''
    const dateRegexToMatch = /^\d{1,2}\/\d{1,2}\/\d{1,5}$/;
    const monthYearRegexToMatch = /^\d{1,2}\/\d{1,5}$/;
    const yearRegexToMatch = /^\d{1,5}$/;
    let regexToMatch = dateRegexToMatch;

    switch (dateFormatEnum) {
      case DateFormatEnum.date.toString():
        regexToMatch = dateRegexToMatch;
        dateStringToConvert = dateString;
        break;
      case DateFormatEnum.monthYear.toString():
        regexToMatch = monthYearRegexToMatch;
        dateStringToConvert = '15/' + dateString;
        break;
      case DateFormatEnum.year.toString():
        regexToMatch = yearRegexToMatch;
        dateStringToConvert = '15/06/' + dateString;
        break;
      default:
        regexToMatch = dateRegexToMatch;
        dateStringToConvert = dateString;
        break;
    }

    // check regex match for both date and date to convert
    if (!regexToMatch.test(dateString) || !dateRegexToMatch.test(dateStringToConvert)) {
      return null;
    }

    try {
      const newMoment = moment.utc(dateStringToConvert, 'DD/MM/y');

      const momentDate = newMoment.toDate();

      const newMomentWithEra = this.getMomentDateWithEra(momentDate, this.state.era);

      if (!newMomentWithEra.isValid()) {
      } else {
        return newMomentWithEra.toDate();
      }
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  handleHasError = () => {

    if (this.props.hasError) {
      return true;
    }

    if (!this.props.required && this.state.value == null) {
      return false;
    }

    return this.state.touched && !this.state.valid;
  }


  getDisplayValue = (date, dateFormatEnum) => {
    if (date == null) {
      return '';
    }

    let format = 'DD/MM/y';

    switch (dateFormatEnum) {
      case DateFormatEnum.date.toString():
        format = 'DD/MM/y';
        break;
      case DateFormatEnum.monthYear.toString():
        format = 'MM/y';
        break;
      case DateFormatEnum.year.toString():
        format = 'y';
        break;
      default:
        format = 'DD/MM/y';
        break;
    }

    return moment.utc(date).format(format);
  }

  // handleOnChange with debounce
  handleChange = (event) => {
    const date = this.getDateFromString(event.target.value ?? '', this.state.dateFormatEnum);
    const isValid = date != null;

    this.setState({
      value: date,
      displayValue: event.target.value,
      valid: isValid,
      touched: true
    });

    this.props.onChange(date);
  }

  getMomentDateWithEra = (date, era) => {
    if (date == null || era == null) {
      return null;
    }

    const dateWithNewEra = moment.utc(date);
    if (era === 'π.Χ.') {
      if (dateWithNewEra.year() > 0) {
        dateWithNewEra.year(-Math.abs(dateWithNewEra.year()) + 1);
      }
    } else if (era === 'μ.Χ.') {
      if (dateWithNewEra.year() < 0) {
        dateWithNewEra.year(Math.abs(dateWithNewEra.year()) + 1);
      }
    }
    return dateWithNewEra;
  }

  handleEraChange = (event) => {
    this.setState({
      era: event.target.value
    });

    if (this.state.value == null) {
      return;
    }

    const dateWithNewEra = this.getMomentDateWithEra(this.state.value, event.target.value);

    const newDateToSet = dateWithNewEra.toDate();

    this.setState({
      value: newDateToSet,
    });

    this.props.onChange(newDateToSet);
  };

  render() {

    return (
      <div className={'flex flex-wrap items-center'}>
        <div className={'flex items-center w-full'}>
          <FormControl className={'grow pr-2'}>
            <TextField
              error={this.handleHasError()}
              label={this.props.label}
              value={this.state.displayValue}
              placeholder={this.getPlaceholder(this.props.dateFormatEnum)}
              onChange={this.handleChange}
              margin="none"
            />
          </FormControl>

          <div className={'w-2'}>
          </div>

          <FormControl className={'w-28'}>
            {/*<InputLabel id="demo-simple-select-label">Era</InputLabel>*/}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.era}
              // label="Era"
              onChange={this.handleEraChange}
            >
              <MenuItem value={'μ.Χ.'}>μ.Χ.</MenuItem>
              <MenuItem value={'π.Χ.'}>π.Χ.</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={'w-full pl-1 pt-1 text-xs'}>
          {this.state.value != null && moment.utc(this.state.value).format('DD/MM/y N')}

          {/*{this.state.touched && !this.state.valid &&*/}
          {/*  <FormHelperText className="HelperText">*/}
          {/*    Λάθος μορφή ημερομηνίας (π.χ. 25/07/317 π.Χ. ή 07/03/2018 μ.Χ.)*/}
          {/*  </FormHelperText>*/}
          {/*}*/}
        </div>
      </div>
    );
  }

  getPlaceholder(dateFormatEnum) {
    switch (dateFormatEnum) {
      case DateFormatEnum.date.toString():
        return '25/07/317';
      case DateFormatEnum.monthYear.toString():
        return '07/2018';
      case DateFormatEnum.year.toString():
        return '2018';
      default:
        return '25/07/317';
    }
  }
}

export default DateTextField;
